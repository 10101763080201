import React, { useState } from "react";
import "./ExtensionCard.css"; // Optional: for styling purposes
import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import logo from "../../assets/images/favicon.png";
import { toast } from "react-toastify";
import { installExtension } from "../../constant/constant";

const ExtensionCard = (props) => {
  console.log("Props : ");
  const { handleToggle, isDisable, isExtEnabled } = props;

  // temp

  // const [isDisableTemp, setisDisableTemp] = useState(false);
  const handleDisabledClick = () => {
    console.log("Clicked");
    toast.error(installExtension);
  };
  // const handletoggletemp = () => {
  //   setisDisableTemp((pre) => !pre);
  // };

  return (
    <div className="Card shadow-sm  rounded position-relative ext">
      <div className="Card-body">
        <div className="d-flex justify-content-between align-items-start">
          <div className="d-flex logo_cart_flex">
            {/* Placeholder for the icon */}
            <div className="me-4 img-container">
              {/* Rm */}
              <img src={logo} className="img-fluid rm-logo"></img>
            </div>
            <div>
              <h5 className="card-title">
                READMAX <span className="text-muted">1.1.4</span>
              </h5>
              <p className="card-text">
                Readmax extension is used to improve reading skills.
              </p>
            </div>
          </div>
          {/* Move the toggle switch to the top-right corner */}
          <div className="form-check form-switch">
            <Tooltip title={isDisable ? "Deactivate" : "Activate"}>
              {/* <input
                disabled={!isExtEnabled}
                className="form-check-input toggleExtSwitch"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={isExtEnabled ? isDisable : false}
                onChange={handleToggle}
              /> */}
              <div
                onClick={() => {
                  if (!isExtEnabled) {
                    handleDisabledClick(); // Trigger toast if extension is not installed
                  }
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!isExtEnabled}
                      checked={isDisable ? true : false}
                    />
                  }
                  onChange={handleToggle}
                />
              </div>
            </Tooltip>
          </div>
        </div>
        <p className="text-center pt-2 mb-0 mt-2">
          <small className="text-muted">
            You can use the toggle switch in this interface to enable or disable
            the Chrome extension.
          </small>
        </p>
      </div>
    </div>
  );
};

export default ExtensionCard;
