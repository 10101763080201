import React, { useState } from "react";

import Navbar from "../../../Navbar";
import Footer from "../../../Footer";
import Loader from "../../../loader/Loader";

import { useLocation } from "react-router-dom";

import "./BlogDetails.css";

const MemberTyp = localStorage.getItem("MemberType");

const BlogDetails = () => {
  const [isLoader, setisLoader] = useState(false);

  const location = useLocation();
  const blog = location.state?.blog; // Retrieve the passed state

  console.log("BBBBBBBBBB : ", blog);

  return (
    <>
      <Navbar MemberType={MemberTyp} />
      {isLoader && <Loader />}
      <div className="blog-details-container">
        {blog ? (
          <>
            <h1 className="blog-title">{blog.title}</h1>
            <p className="blog-content">{blog.text}</p>
          </>
        ) : (
          <p className="no-data">
            No data received. Please navigate from the home page.
          </p>
        )}
      </div>
      {!isLoader && <Footer />}
    </>
  );
};

export default BlogDetails;
