import React, { useEffect, useState } from "react";

import "./blog.css";

import Footer from "../../Footer";
import Navbar from "../../Navbar";
import Loader from "../../loader/Loader";
import Banner from "./Banner/Banner";
import Card from "./card/Card";
import { apiCall } from "../../../API/apiCall";
import { toast } from "react-toastify";

const MemberTyp = localStorage.getItem("MemberType");

const Blog = () => {
  const [isLoader, setisLoader] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [paginationData, setPaginationData] = useState({
    totalCount: 0,
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: null,
  });

  useEffect(() => {
    fetchData(paginationData.currentPage, 10);
  }, [paginationData.currentPage]);

  const fetchData = async (page, limit) => {
    try {
      setisLoader(true);
      const response = await apiCall({
        url: "/blogs/",
        method: "GET",
        params: { page, limit },
      });

      setBlogs(response?.data);
      setPaginationData(response?.pagination);

      setisLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
      setisLoader(false);
    }
  };

  return (
    <>
      <Navbar MemberType={MemberTyp} />
      {isLoader && <Loader />}
      <div>
        <h1>Blog Page</h1>
        {/* <Banner blogs={blogs} />
        <Card blogs={blogs} /> */}
      </div>
      {!isLoader && <Footer />}
    </>
  );
};

export default Blog;
