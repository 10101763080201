import React from "react";

import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";

const FaqAccordion = ({ faqs, expanded, handleExpansion, prefix }) => {
  return (
    <>
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === `${prefix}-${index}`}
          onChange={handleExpansion(`${prefix}-${index}`)}
          className="accordion_heading"
        >
          <Tooltip title={faq.question} placement="top">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              className="accordion_summary_text"
            >
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
          </Tooltip>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default FaqAccordion;
