import axios from "axios";
import { toast } from "react-toastify";
import { BASEURL } from "../constant/constant";

// Create an Axios instance with base configuration
const axiosInstance = axios.create({
  baseURL: BASEURL, // You can set your base URL here
  timeout: 5000, // Optional: Set timeout for requests
});

// Add an interceptor to handle common configurations
axiosInstance.interceptors.request.use(
  (config) => {
    // You can add auth token or other common headers here
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add an interceptor to handle errors globally if needed
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle error responses, e.g., unauthorized access
    if (error.response && error.response.status === 401) {
      // Redirect to login or handle unauthorized access
    }
    return Promise.reject(error);
  }
);

/**
 * Custom Axios API call function
 * @param {string} url - The endpoint URL
 * @param {string} method - HTTP method ('GET', 'POST', 'PUT', 'DELETE')
 * @param {Object} [data] - Data to send in the request body (for POST, PUT, etc.)
 * @param {Object} [headers] - Custom headers to send with the request
 * @param {Object} [params] - Query parameters for the request
 * @returns {Promise} - Returns a promise with the response data or error
 */
export const apiCall = async ({
  url,
  method,
  data = {},
  headers = {},
  params = {},
}) => {
  try {
    const response = await axiosInstance({
      url,
      method,
      data,
      headers,
      params,
    });
    return response.data;
  } catch (error) {
    // Handle error response or rethrow the error for higher-level handling
    if (!error.response) {
      // Network error or server is not reachable
      console.error("Network error or server is not reachable:", error.message);
      toast.error(
        "The server is currently unreachable. Please try again later."
      );
    } else {
      console.error("API call error:", error);
    }
    throw error;
  }
};
