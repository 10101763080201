import React, { useEffect, useState } from "react";

import "./faq.css";

import Footer from "../../Footer";
import Navbar from "../../Navbar";
import Loader from "../../loader/Loader";
import Button from "@mui/material/Button";

const MemberTyp = localStorage.getItem("MemberType");

import faqimg from "../../../assets/images/faq_bg.svg";
import Grid from "@mui/material/Grid";

import { apiCall } from "../../../API/apiCall";
import { toast } from "react-toastify";
import FaqAccordion from "./FaqAccordian";

const Faq = () => {
  const [isLoader, setisLoader] = useState(false);
  const [firstHalf, setFirstHalf] = useState([]);
  const [secondHalf, setSecondHalf] = useState([]);
  const [paginationData, setPaginationData] = useState({
    totalCount: 0,
    currentPage: 1,
    totalPages: 1,
    previousPage: null,
    nextPage: null,
  });
  const [expanded, setExpanded] = React.useState("");

  useEffect(() => {
    fetchData(paginationData.currentPage, 10);
    setExpanded("");
  }, [paginationData.currentPage]);

  const fetchData = async (page, limit) => {
    try {
      setisLoader(true);
      const response = await apiCall({
        url: "/faq/",
        method: "GET",
        params: { page, limit },
        headers: {
          "x-api-token": process.env.REACT_APP_API_STATIC_TOKEN,
        },
      });

      setPaginationData(response.pagination);

      if (response?.data?.length) {
        const splitIndex = Math.ceil(response?.data?.length / 2);
        setFirstHalf(response?.data?.slice(0, splitIndex));
        setSecondHalf(response?.data?.slice(splitIndex));
      }

      setisLoader(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
      setisLoader(false);
    }
  };

  const handleNextPage = () => {
    if (paginationData.nextPage) {
      setPaginationData((prev) => ({
        ...prev,
        currentPage: paginationData.nextPage,
      }));
    }
  };

  const handlePrevPage = () => {
    if (paginationData.previousPage) {
      setPaginationData((prev) => ({
        ...prev,
        currentPage: paginationData.previousPage,
      }));
    }
  };

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Navbar MemberType={MemberTyp} />
      {isLoader && <Loader />}
      <div className="main-container faq_container">
        <div className="faq_banner container">
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <div className="faq_content">
                <h2>Frequently Asked Questions</h2>
                <p>
                  Live Ink® has conducted multiple scientific studies with
                  students of all ages and abilities, from 6 th grade through
                  medical school. The published papers, with links in the
                  Information section of the Readmax website, describe the
                  significant benefits of reading in Live Ink text format,
                  results that have been repeated in multiple settings, with
                  students from the US and other countries, and differing
                  demographic backgrounds.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={5} className="pading_unset">
              <div className="faq_img">
                <img src={faqimg} alt="" />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="faq_accordion">
        <div className="container">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FaqAccordion
                faqs={firstHalf}
                expanded={expanded}
                handleExpansion={handleExpansion}
                prefix="first"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FaqAccordion
                faqs={secondHalf}
                expanded={expanded}
                handleExpansion={handleExpansion}
                prefix="second"
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="faq_pagination container">
        <div className="next_btns">
          <div className="next_button">
            <Button
              variant="outlined"
              className="next_btn btn"
              onClick={handlePrevPage}
              disabled={!paginationData.previousPage}
            >
              Prev
            </Button>
          </div>
          <div className="prev_button">
            <Button
              variant="contained"
              className="next_btn btn"
              onClick={handleNextPage}
              disabled={!paginationData.nextPage}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      {!isLoader && <Footer />}
    </>
  );
};

export default Faq;
